import React, {useState} from 'react';

import { files, images } from '../../constants';
import {AppWrap, MotionWrap} from '../../wrapper';
import {client} from '../../Client';

import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    message: ''
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const {username, email, message} = formData;

  const handleChangeInput = (e) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value});
  }

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: username,
      email: email,
      message: message,
    }

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <h2 className="head-text">Take a coffee & chat with me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:milostanaskovic.dev.data@gmail.com" className="p-text">milostanaskovic.dev.data@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.cv} alt="Milos Tanaskovic PDF CV" />
          <a href={files.MTCV} download className="p-text">Download My Updated CV</a>
        </div>
        {/*<div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (123) 456-7890" className="p-text">+1 (123) 456-7890</a>
        </div>*/}
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input 
              className="p-text" 
              type="text" 
              placeholder="Your Name" 
              name="username" 
              value={username} 
              onChange={handleChangeInput} 
            />
          </div>
          <div className="app__flex">
            <input 
              className="p-text" 
              type="email" 
              placeholder="Your Email" 
              name="email" 
              value={email} 
              onChange={handleChangeInput} 
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button 
            type="button" 
            className="p-text" 
            onClick={handleSubmit}
          >
            {!loading ? 'Send Message' : 'Sending...'}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)